<template>
    <div id="ap">
        <a-layout>
            <div class="content-header">
                <a-affix :offset-top="0" style="width: 100%;">
                    <a-row type="flex" justify="space-between">
                        <a-col span="12">
                            <common-page-btn @save="$refs.noticeForm._show()"
                                             @category-list="$refs.category._show()"></common-page-btn>
                        </a-col>
                        <a-col span="12">
                            <a-row type="flex" align="middle" justify="end" :gutter="3">
                                <a-col span="12">
                                    <a-input v-model="param.keyword" allowClear search @pressEnter="getList"
                                             @on-clear="getList" placeholder="关键词查询"></a-input>
                                </a-col>

                                <a-col span="6" v-if="getOrganId() <= 10000">
                                    <a-select v-model="param.organId" style="width:100%" @change="doRefresh">
                                        <a-select-option :value="0">所有单位</a-select-option>
                                        <a-select-option :value="organ.organId" v-for="(organ, index) in getOrganList()"
                                                         :key="index">{{
                                                             organ.name }}</a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col span="6">
                                    <a-select v-model="param.categoryId" style="width:100%" @change="doRefresh">
                                        <a-select-option value="">所有分类</a-select-option>
                                        <a-select-option :value="item.categoryId" v-for="(item, index) in categoryList"
                                                         :key="index">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-affix>
            </div>
        </a-layout>

        <a-layout>
            <a-layout-content>
                <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                         :style="{ background: '#FFF' }" size="small" row-key="noticeId" border :columns="keys" ref="list"
                         :loading="loading" :data-source="data.records">
                    <!--标题-->
                    <template slot-scope="row" slot="table-title">
                        <span>【{{ row.categoryName }}】</span>
                        <a @click="$refs.detail.show(row.noticeId)">{{ row.title }}</a>
                    </template>
                    <!--状态-->
                    <template slot-scope="row" slot="status">
                        <a-tooltip :title="row.status">
                            <template v-if="row.status == '已发布'">
                                <a-icon type="check-circle" style="color:#090" />
                            </template>
                            <template v-else>
                                <a-icon type="stop" />
                            </template>
                        </a-tooltip>
                    </template>
                    <!--置顶-->
                    <template slot-scope="row" slot="top">
                        <template v-if="row.isTop">
                            <a-icon type="check-circle" style="color:#090" />
                        </template>
                        <template v-else>
                            <a-icon type="stop" />
                        </template>
                    </template>
                    <!--操作-->
                    <template slot-scope="row" slot="action">
                        <a-button-group shape="circle" size="small">
                            <a-tooltip title="编辑"><a-button type="link" v-has="'notice:save'" icon="edit"
                                          @click="doEdit(row.noticeId)"></a-button></a-tooltip>
                            <a-tooltip title="删除"><a-button type="link" v-has="'notice:del'" icon="delete"
                                          @click="del(row.noticeId)"></a-button></a-tooltip>
                        </a-button-group>
                    </template>
                </a-table>
            </a-layout-content>
        </a-layout>
        <a-row>
            <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                              @showSizeChange="setLimit" :current="data.current" />
            </a-col>
        </a-row>
        <a-back-top />

        <notice-form ref="noticeForm" @success="getList" :category="categoryList" :organId="param.organId"></notice-form>
        <category ref="category" :organId="param.organId" :category="categoryList" @success="getCategoryList"></category>
        <view-detail ref="detail" />
    </div>
</template>

<script>
import utils from '@/common/utils';
import noticeForm from './components/noticeForm.vue';
import apiUtil from '@/common/apiUtil';
import category from './components/category.vue';
import ViewDetail from './components/view-detail.vue';
export default {
    components: { noticeForm, category, ViewDetail },
    data() {
        return {
            loading: false,
            data: [],
            param: {
                page: 1,
                limit: 20,
                organId: apiUtil.getOrganId(),
                categoryId: '',
                keyword: ''
            },
            keys: [
                // { title: 'ID', dataIndex: 'noticeId', width: 100, align: 'center' },
                { title: '标题', scopedSlots: { customRender: 'table-title' }, align: 'left', ellipsis: true, width: 200 },
                { title: '发布人', dataIndex: 'staffName', width: 100, ellipsis: true },
                { title: '发布时间', dataIndex: 'createTime', width: 160, ellipsis: true },
                { title: '置顶', scopedSlots: { customRender: 'top' }, align: 'center', width: 100, ellipsis: true },
                { title: '状态', scopedSlots: { customRender: 'status' }, align: 'center', width: 100, ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ],
            categoryList: []
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        this.getCategoryList();
    },
    watch: {
        "param.organId": {
            handler(v) {
                this.param.categoryId = '';
                this.getCategoryList();
            }
        }
    },
    methods: {
        ...apiUtil,
        //删除
        del(id) {
            this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
                utils.showSpin();
                this.http.delete('/platform/notice/del/' + id).then(ret => {
                    if (ret.code == 200) {
                        this.utils.success('操作成功！').then(() => {
                            this.getList();
                        });
                    }
                });
            });
        },
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        //编辑
        doEdit(id) {
            utils.showSpin();
            this.http.get("/platform/notice/detail/" + id, {}).then(ret => {
                this.$refs.noticeForm._show(ret.data);
            });
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.http
                .get('/platform/notice/list', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        // 获取分类
        getCategoryList() {
            this.http.get("/platform/notice/category", { organId: this.param.organId }).then(ret => {
                this.categoryList = ret.data;
            });
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    }
};
</script>

<style scoped></style>
